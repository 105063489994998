import axios from 'axios'
import { useEffect, useState, useContext } from "react"
import { UserContext } from '../../hooks/UserContext';
import SelectEventCategory from './SelectEventCategory';

import { Form, Modal } from "react-bootstrap"
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox';

export default function NewEvent({addEventItem}) {
  const [show, setShow] = useState(false);
  const { user } = useContext(UserContext);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Form data
  const [date, setDate] = useState();
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [body, setBody] = useState(); 
  const [image, setImage] = useState();
  const [keyEvent, setKeyEvent] = useState(false); 
  
  const [errorMessage, setErrorMessage] = useState('');

  const checkKeyEventExistence = async (selectedDate) => {
    try {
      const response = await axios.get(`${apiUrl}/events`, {
        headers: { Authorization: `Bearer ${user.token}` }
      });
      const events = response.data;
      const selectedDateObj = new Date(selectedDate);
      const selectedMonth = selectedDateObj.getMonth() + 1;
      const selectedDay = selectedDateObj.getDate();
      const keyEventFound = events.some(event => {
        const eventDateObj = new Date(event.date);
        return (
          eventDateObj.getMonth() + 1 === selectedMonth &&
          eventDateObj.getDate() === selectedDay &&
          event.key_event
        );
      });
      return keyEventFound;
    } catch (error) {
      console.error("Error checking key event existence:", error);
      return false;
    }
  };
  

  const handleClose = () => {
    setShow(false);
    setDate('');
    setTitle('');
    setDescription('');
    setBody('');
    setImage('');
    setKeyEvent(false);
  }
  const handleShow = () => setShow(true);

  const createEvent = async () => {
    let url = `${process.env.REACT_APP_API_URL}/events`;
    let data;
  
    try {
      const response = await axios.post(url, {
        date: date,
        categories: categories.map(cat => cat.value),
        title: title,
        description: description,
        body: body,
        image: image,
        key_event: keyEvent,
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' },
      });
      data = response.data;
    } catch (error) {
      console.error(error);
    }
  
    setDate('');
    setTitle('');
    setDescription('');
    setBody('');
    setImage('');
    setKeyEvent(false);
  
    return data;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMessage('');
    if (keyEvent) {
      const exists = await checkKeyEventExistence(date);
      if (exists) {
        setErrorMessage("A key event already exists for this date.");
        return; // Stop submission if validation fails.
      }
    }

    setShow(false);
    console.log("New event is going to be created here")
    const result = await createEvent();
    const eventItem = {
      label: `${result.date} - ${result.title}`,
      value: result.id,
    }
    console.log(`Event request got following response: `)
    console.log(result)
    addEventItem(eventItem);
  }

  const handleCategoryChange = (currentCategory) => {
    setCategories(currentCategory);
    console.log(`We changed category and selected category is ${currentCategory}`)
    console.log(currentCategory)
  };

  const onBeforeSend = (e) => {
    e.formData.append('folder', `event_images/${date}`);
  }

  const onUpload = (event) => {
    const url = JSON.parse(event.xhr.response).location
    setImage(url);
  }


  return (
    <>
      <Button label="Add" onClick={handleShow} icon="pi pi-plus" className="p-button-rounded p-button-text" size="small"/>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formEventDate">
            <Form.Label>Date (YYYY-MM-DD, e.g. 1981-09-30): </Form.Label>
            <Form.Control type="text" onChange={e => setDate(e.target.value)} value={date} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formEventCategory">
            <Form.Label>Category: </Form.Label>
            {/* <Form.Control type="text" onChange={e => handleCategoryChange(e.target.value)} value={categories} placeholder="" /> */}
            <SelectEventCategory
              onCategorySelect={handleCategoryChange}
            />
          </Form.Group>
          <Form.Group controlId="formEventTitle">
            <Form.Label>Title: </Form.Label>
            <Form.Control type="text" onChange={e => setTitle(e.target.value)} value={title} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formEventDescription">
            <Form.Label>Description: </Form.Label>
            <Form.Control type="text" as='textarea' onChange={e => setDescription(e.target.value)} value={description} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formUploadImage">
            <Form.Label>Image: </Form.Label>
            <Form.Control type="text" value={image} placeholder="" readOnly />
            <FileUpload
              mode="basic"
              name="file"
              url={`${apiUrl}/upload`}
              accept="image/*"
              maxFileSize={5000000}
              onBeforeSend={onBeforeSend}
              onUpload={onUpload}
            />
          </Form.Group>
          <Form.Group controlId="formKeyEvent">
            <div className="d-flex align-items-center">
              <Checkbox
                inputId="keyEvent"
                onChange={(e) => setKeyEvent(e.checked)}
                checked={keyEvent}
              />
              <label htmlFor="keyEvent" className="ml-2">Mark as Key Event</label>
            </div>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
