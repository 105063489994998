import React from 'react';

const ParallaxImage = ({ imageUrl }) => {
  const backgroundImageStyle = {
    backgroundImage: `url(${imageUrl})`,
    height: '100px',
    // backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return <div className="parallax-img" style={backgroundImageStyle}></div>;
};

export default ParallaxImage;
