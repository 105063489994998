import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';

export default function SelectEventCategory({ onCategorySelect, selectedEvent }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [value, setValue] = useState([]);
  const { user } = useContext(UserContext);
  
  useEffect(() => {
    async function fetchCategories() {
      if (selectedEvent) {
        await getOptions(selectedEvent);
      } else {
        await getOptions();
      }
    }

    fetchCategories();
  }, []);

  const getOptions = async (selectedEvent) => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });

    const data = res.data;

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))

    if (selectedEvent) {
      const eventResp = await axios.get(`${process.env.REACT_APP_API_URL}/events/${selectedEvent.id}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });

      const event = eventResp.data;

      if (event && event.categories) {
        const selected = options.filter((item) => {
          return event.categories.some((category) => category === item.value);
        });
        setValue(selected)
        onCategorySelect(selected)
      }
    }

    setSelectOptions(options)
  }

  const handleChange = (e) => {
    if (e) {
      onCategorySelect(e);
      setValue(e);
      console.log(`Number of selected categories: ${e.length}`);
    } else {
      onCategorySelect({});
    }
  }

  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 2 })
      }}
      isMulti
      className="selectbox"
      options={selectOptions}
      value={value}
      onChange={handleChange}
      placeholder={<div>Select event category</div>}
    />
  )
}
