import React, { useState, useEffect, useContext } from 'react';
import parse, { domToReact } from 'html-react-parser';
import axios from 'axios';
import { UserContext } from './hooks/UserContext';
import CourseCardPreview from './components/course/CourseCardPreview'

import './css/EventPreview.css';
import statusBar from './css/images/card-preview-background/header-bar.png'
import ParallaxImage from './components/preview/ParallaxImage';



const EventPreview = ({ content, selectedEvent }) => {
  const [eventContent, setEventContent] = useState('');
  const [event, setEvent] = useState();
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function fetchEvent() {
      if (selectedEvent) {
        const data = await getEvent(selectedEvent.id);
        setEvent(data);
      }
    }

    fetchEvent()
  }, [selectedEvent]);

  const getEvent = async (eventId) => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });
    
    return res.data;
  }

  useEffect(() => {
    console.log('Event preview updated');
    if (content) {
      var parser = new DOMParser();
      var htmlDoc = parser.parseFromString(content, 'text/html');
      setEventContent(content)
    } else {
      setEventContent('')
    }
  }, [content]);


  const options = {
    replace: (domNode) => {
      // Handle custom quiz tag
      if (domNode.type === "tag" && domNode.name === "quiz" && domNode.attribs) {
        return (
          <div className="quiz-container">
            <p>
              {domNode.attribs.title}
            </p>
            <p>
              {domNode.attribs.question}
            </p>
            <div class="answers">
              <img class="answer-button" alt="quiz button" />
              <div class="text">{domNode.attribs.answer1}</div>
            </div>
            <div class="answers">
              <img class="answer-button" alt="quiz button" />
              <div class="text">{domNode.attribs.answer2}</div>
            </div>
            <div class="answers">
              <img class="answer-button" alt="quiz button" />
              <div class="text">{domNode.attribs.answer3}</div>
            </div>
          </div>
        )
      }

      // Handle custom course tag
      if (domNode.type === "tag" && domNode.name === "course" && domNode.attribs) {
        return (
          <CourseCardPreview courseId={domNode.attribs.id} />
        )
      }

      // Handle custom note tag
      if (domNode.type === "tag" && domNode.name === "note" && domNode.attribs) {
        const node = domNode.attribs;
        return (
          <div className="note-container">
            <div className="note-header">
              <img className="note" src={domNode.attribs.img} />
              <p className="note">  <strong>{domNode.attribs.title}</strong> </p>
            </div>
            <div className="note-body">
              {domToReact(domNode.children, options)}
            </div>
          </div >
        )
      }

      // Set image width for preview
      if (domNode.attribs && domNode.type === "tag" && domNode.name === "img") {
        domNode.attribs.class = "card";
        return domNode;
      }

      // Wrap button into div to align it properly to center
      if (domNode.attribs && domNode.type === "tag" && domNode.name === "button") {
        console.log({ domNode })
        return (
          <div class="button-container">
            <button>{domToReact(domNode.children, options)}</button>
          </div>
        );
      }

      // Do not show text without <p>
      // <div>Some text</div> will not be shown
      if (domNode.type === "tag" && domNode.name === "div" && domNode.children) {
        const result = domNode.children.filter(element => element.type !== 'text');
        console.log('Filtered text elements without <p>');
        domNode.children = result;
        return domNode;
      }
    }
  };

  return (
    <div className="Card-preview-wrapper">
      <img className="status-bar" src={statusBar} alt="status bar" />
      {/* { event && event.categories[0].image && <ParallaxImage imageUrl={event.categories[0].image} /> } */}
      {event?.categories?.length > 0 && event.categories[0].image && (
        <ParallaxImage imageUrl={event.categories[0].image} />
      )}
      <div className="card-content">
        <div className="Card-preview">
          {event && event.image &&
            <div className="square-image">
              <img src={event.image} alt="Square event illustration" /> 
            </div>
          }
          <div className="event-content">
            {parse(eventContent, options)}
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default EventPreview;
